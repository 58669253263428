import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import { faHome } from '@fortawesome/pro-light-svg-icons'

import { Box, Columns } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import type { CampaignFilters } from '@hooks/useCampaigns'

import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import useCampaignStatuses from '@hooks/useCampaignStatuses'

import { CampaignStatusModel } from '@models/types'

import Orders from '../Orders'

type BuildPageFilterFieldParams = {
  campaignStatuses: CampaignStatusModel[],
}

const buildPageFilterFields = (params: BuildPageFilterFieldParams) => {
  const { campaignStatuses } = params

  const style = { marginBottom: 16 }
  const boxProps = { marginBottom: 'large' }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        name: 'string',
        placeholder: 'Search by campaign name or AIM#',
        style,
      },
      key: 'string',
      label: 'Search',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        name: 'status',
        style,
      },
      key: 'status',
      label: 'Status',
      values: campaignStatuses.map(campaignStatus => (
        { key: campaignStatus.name, label: campaignStatus.displayName }
      )),
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'clients',
        entityTitleKey: 'name',
      },
      key: 'clientId',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        name: 'startDate',
        style,
      },
      key: 'startDate',
      label: 'Start Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        name: 'endDate',
        style,
      },
      key: 'endDate',
      label: 'End Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'Latest First',
        inputComponent: 'select',
        name: 'sortBy',
        style,
      },
      key: 'sortBy',
      label: 'Sort By',
      values: [
        { key: 'asc', label: 'Oldest First' },
      ],
    },
  ]
}

interface PageContentProps {
  pageFilters: CampaignFilters,
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  const { campaignStatuses } = useCampaignStatuses({ performHttpRequests: true })

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  return (
    <>
      <Helmet>
        <title>Order Monitoring | AIM Digital</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="issuesTasks"
        tabBarItems={[
          {
            href: '#/',
            icon: faHome,
            key: 'issuesTasks',
            title: 'Issues & Tasks',
          },
        ]}
        title="Order Monitoring"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <Orders pageFilters={pageFilters} />
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields({ campaignStatuses })}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
